import React, { useEffect, useState } from 'react';
import BaseLayout from '../../components/templates/BaseLayout';
import TimerIcon from '../../components/atoms/icons/TimerIcon';
import { formatActivityDateTime } from '../../utils/helpers/activity';
import LocationPin from '../../components/atoms/icons/LocationPin';
import LinkIcon from '../../components/atoms/icons/LinkIcon';
import PaperIcon from '../../components/atoms/icons/PaperIcon';
import SEO from '../../components/atoms/utility/seo';
import { navigate } from 'gatsby';

const seoData = {
  title: "Dive Into Grand Rapids's Real Time activities.",
  description:
    'Explore the vibrant music, family, food, and beer scenes in Grand Rapids with flockx - the app that helps you discover and enjoy the best local experiences in the city.',
  keywords: [
    'tacos',
    'food',
    'influencer',
    'family',
    'Grand Rapids',
    'community',
    'exploration',
    'experience',
    'inspiration',
    'live music',
    'mobile app',
    'flockx',
  ],
};

const ActivityDetailPage = ({ params }) => {
  const activityId = params['id'];
  const [activityData, setActivityData] = useState(null);
  const [loading, setLoading] = useState(false);

  const {
    og_image_url: ogImageUrl,
    community_banner_url: communityBannerUrl,
    community_name: communityName,
    name: activityName,
    point_of_interest_name: poiName,
    point_of_interest: poiId,
    description,
    community_image_url: communityLogoImage,
    start_time_utc: activityStartTime,
    end_time_utc: activityEndTime,
    url: website,
  } = activityData || {};

  const fetchActivityData = async () => {
    try {
      setLoading(true);
      const baseURL = '.netlify/functions/es-activity';
      const url = new URL(baseURL, window.location.origin);

      url.searchParams.append('activityId', activityId);

      const response = await fetch(url.href);
      const json = await response.json();

      setActivityData(json.activityData.properties);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log('Error in fetchActivityData: ', JSON.stringify(err, null, 2));
    }
  };

  useEffect(() => {
    if (activityId) {
      fetchActivityData();
    }
  }, [activityId]);

  const handlePoiClick = () => {
    navigate(`/business/${poiId}`);
  };

  const handleCommunityClick = () => {
    const slug = communityName.toLowerCase().replaceAll(' ', '-');
    navigate(`/community/${slug}`);
  };

  const formattedActivityDateTime = formatActivityDateTime({
    startDate: activityStartTime,
    endDate: activityEndTime,
  });

  if (loading || !activityName) {
    return (
      <BaseLayout
        mainSectionClass="z-20 relative flex-grow h-full w-full pt-[40px] mb-10"
        hideHeroImage
        customHeaderClass=" "
      >
        <SEO
          title={seoData.title}
          description={seoData.description}
          keywords={seoData.keywords}
        />
        <div className="animate-pulse skeleton group w-full bg-gray-200 h-[246px] md:h-[400px]" />
        <div className="animate-pulse skeleton group py-[18px] px-4 sm:px-6 flex-col justify-start items-start gap-4 inline-flex">
          <div className="w-[300px] h-6 md:w-[644px] md:h-[57px] bg-gray-200 rounded-[20px]" />
          <div className="w-[200px] h-6 md:w-[644px] md:h-[57px] bg-gray-200 rounded-[20px]" />
          <div className="w-[200px] h-6 md:w-[644px] md:h-[57px] bg-gray-200 rounded-[20px]" />
          <div className="w-[200px] h-6 md:w-[644px] md:h-[57px] bg-gray-200 rounded-[20px]" />
        </div>
      </BaseLayout>
    );
  }

  return (
    <BaseLayout
      mainSectionClass="z-20 relative flex-grow h-full w-full pt-[40px] mb-10"
      hideHeroImage
      customHeaderClass=" "
    >
      <SEO
        title={seoData.title}
        description={seoData.description}
        keywords={seoData.keywords}
      />
      <div className="w-full h-[246px] md:h-[600px]">
        <img
          className="w-full h-full object-cover"
          src={ogImageUrl || communityBannerUrl}
        />
      </div>
      <div className="pt-4 lg:pt-8 px-8 md:px-32">
        <div className="flex-col justify-start items-start inline-flex">
          <div className="mb-[16px] md:mb-[24px] justify-start items-center gap-1.5 inline-flex">
            <div className="text-gray-900 text-[22px] md:text-[44px] font-bold font-['DM Sans'] leading-tight">
              {activityName}
            </div>
          </div>
          <div
            className="cursor-pointer mb-[12px] md:mb-[24px] justify-start items-start gap-1.5 inline-flex"
            onClick={handleCommunityClick}
          >
            <div className="px-2 py-1 bg-zinc-100 rounded-[60px] justify-start items-center gap-1.5 flex">
              <img
                src={communityLogoImage}
                alt="community image"
                className="drop-shadow-xl w-[24px] md:w-[35px]"
              />
              <div className="text-gray-900 text-xs md:text-lg font-medium font-['DM Sans'] leading-tight">
                {communityName}
              </div>
            </div>
          </div>
          <div className="mb-[12px] md:mb-[24px] flex-col justify-start items-start flex">
            <div className="mb-[12px] md:mb-[24px] justify-start items-center gap-1.5 flex">
              <TimerIcon className="w-6 h-6 md:w-8 md:h-8 bg-zinc-100 rounded-lg p-[5px] md:[7.5px]" />
              <div className="justify-start items-center gap-1 flex">
                <div className="text-gray-900 text-sm md:text-2xl font-medium font-['DM Sans'] leading-tight">
                  {formattedActivityDateTime}
                </div>
              </div>
            </div>

            <div className="mb-[12px] md:mb-[24px] justify-start items-center gap-1.5 flex">
              <LocationPin className="w-6 h-6 md:w-8 md:h-8 bg-zinc-100 rounded-lg p-[5px] md:[7.5px]" />
              <div
                className="cursor-pointer justify-start items-center gap-1 flex"
                onClick={handlePoiClick}
              >
                <div className="text-indigo-500 text-sm md:text-2xl font-medium font-['DM Sans'] leading-tight">
                  {poiName}
                </div>
              </div>
            </div>

            {!!website && (
              <div className="mb-[12px] md:mb-[24px] justify-start items-center gap-1.5 flex">
                <LinkIcon className="w-6 h-6 md:w-8 md:h-8 bg-zinc-100 rounded-lg p-[5px] md:[7.5px]" />
                <a
                  className="justify-start items-center gap-1 flex"
                  href={website}
                  target="_blank"
                >
                  <div className="underline text-indigo-500 text-sm md:text-2xl font-medium font-['DM Sans'] leading-tight">
                    more info
                  </div>
                </a>
              </div>
            )}

            <div className="mb-[12px] md:mb-[24px] justify-start items-center gap-1.5 flex">
              <PaperIcon className="w-6 h-6 md:w-8 md:h-8 bg-zinc-100 rounded-lg p-[5px] md:[7.5px]" />
              <div className="justify-start items-center gap-1 flex">
                <div className="text-gray-900 text-sm md:text-2xl font-bold font-['DM Sans'] leading-tight">
                  Description
                </div>
              </div>
            </div>

            <div className="mb-[12px] md:mb-[24px] text-gray-900 text-xs md:text-base font-normal font-['DM Sans']">
              {description}
            </div>
          </div>
        </div>
      </div>
    </BaseLayout>
  );
};

export default ActivityDetailPage;
