import React from 'react';

const LinkIcon = ({ className }) => {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M19.5141 14.4859C17.3662 12.338 13.8838 12.338 11.7359 14.4859L6.23591 19.9859C4.08803 22.1338 4.08803 25.6162 6.23591 27.7641C8.3838 29.912 11.8662 29.912 14.0141 27.7641L15.5287 26.2494M14.4859 19.5141C16.6338 21.662 20.1162 21.662 22.2641 19.5141L27.7641 14.0141C29.912 11.8662 29.912 8.3838 27.7641 6.23591C25.6162 4.08803 22.1338 4.08803 19.9859 6.23591L18.4739 7.74788"
        stroke="#111827"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LinkIcon;
